import React from 'react'
import Img from 'gatsby-image'
import styled from 'styled-components'
import { StaticQuery, graphql, Link } from 'gatsby'

import theme from '../styles/theme'
import bgImg from '../images/abstract-bg-flipped.png'
import AnchorLink from '../components/AnchorLink'

const TopProjectsWrap = styled.div`
  position: relative;
  .container,
  .row {
    position: relative;
  }
`

const FadedBGImage = styled(Img)`
  height: 90%;
  max-height: 100vw;
  width: 50%;
  /* background-image: url(${(props) => props.img}); */
  position: absolute !important;
  bottom: 0;
  right: 0;
  z-index: 0;
  opacity: 0.25;
  /* object-fit: contain; */
  background-size: cover;
`

const TopProjectsBlock = (props) => (
  <StaticQuery
    query={graphql`
      query TopProjectsQuery {
        contentfulTopProjects {
          headingNormalColor
          headingAccentColor
          subHeading {
            childMarkdownRemark {
              html
            }
          }
          topProjects {
            id
            name
            slug
            coverImage {
              title
              fluid(maxWidth: 1800) {
                ...GatsbyContentfulFluid_noBase64
              }
            }
          }
        }
      }
    `}
    render={(data) => {
      let projectsInRow = data.contentfulTopProjects.topProjects.length
      projectsInRow = 12 / projectsInRow

      const bg = props.bg ? props.bg : 'white-bg'
      const projectsBG = props.projectsBG

      return (
        <TopProjectsWrap
          className={`portfolio-area style-3 ${bg} section-pt pb-120 clearfix`}
        >
          <FadedBGImage fluid={projectsBG.fluid} />
          <div className="container">
            <AnchorLink
              link={
                data.contentfulTopProjects.headingNormalColor +
                ' ' +
                data.contentfulTopProjects.headingAccentColor
              }
            />
            <div className="row">
              <div className="col-xs-12 text-center">
                <div className="section-title pb-80">
                  <h2 className="mb-15">
                    {data.contentfulTopProjects.headingNormalColor}{' '}
                    <span className="theme-color">
                      {data.contentfulTopProjects.headingAccentColor}
                    </span>
                  </h2>
                  <div
                    dangerouslySetInnerHTML={{
                      __html:
                        data.contentfulTopProjects.subHeading
                          .childMarkdownRemark.html,
                    }}
                  />
                </div>
              </div>
              <div className="portfolio-grid fitRows-grid">
                {data.contentfulTopProjects.topProjects.map((project) => (
                  <div
                    key={project.id}
                    className={`grid-item col-xs-12 col-sm-12 col-md-${projectsInRow}`}
                  >
                    <div className="single-portfolio">
                      <Link to={`/projects/${project.slug}`}>
                        <Img fluid={project.coverImage.fluid} />
                      </Link>
                      <div className="project-title text-left">
                        <Link to={`/projects/${project.slug}`}>
                          <h4>{project.name}</h4>
                        </Link>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
          <div className="row">
            <div className="view-all text-center">
              <Link
                className="btn theme-btn mt-40"
                id="load-more-btn"
                to="/projects/"
              >
                See All Projects
              </Link>
            </div>
          </div>
        </TopProjectsWrap>
      )
    }}
  />
)

export default TopProjectsBlock
