import React from 'react'
import styled from 'styled-components'
import {rgba} from 'polished'
import { StaticQuery, graphql, Link } from "gatsby"
import Img from 'gatsby-image'

import { media } from '../styles/utils'


const ConnectBlogBlockWrap = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;

  ${media.medium`
    grid-template-columns: 1fr;
  `}
`;

const Halfer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40vw;
  position: relative;

  ${media.medium`
    height: 80vw;
  `}

  .bgImg {
    position: absolute !important;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    object-fit: cover;
    z-index: 1 !important;
  }

  ul {
    margin: 0;
    padding: 0;
  }

  &.connectSide {
    flex-direction: column;
    /* padding: 100px 0; */
    /* background-color: ${props => props.theme.color.black};
    color: #fff; */
  }
  
  &.blogSide {
    background-position: right;
    background-size: cover;
    flex-direction: column;

    div,
    span,
    a {
      z-index: 2;
    }

    .heading {
      font-size: 36px;
      /* font-weight: bold; */
      margin-bottom: 40px;
      color: ${props => props.theme.color.white};
    }

    &:after {
      content: "";
      width: 100%;
      height: 100%;
      background-color: ${props => props.theme.color.black && rgba(props.theme.color.black, 0.6)};
      position: absolute;
      top: 0;
      left: 0;
      z-index: 1;
    }
  }
`

const SocialSpan = styled.span`
  font-size: 30px !important;

  &.fa-facebook-f:hover {
    color: ${props => props.theme.socialColor.facebook};
  }
  &.fa-twitter:hover {
    color: ${props => props.theme.socialColor.twitter};
  }
  &.fa-instagram:hover {
    color: ${props => props.theme.socialColor.instagram};
  }
  &.fa-linkedin-in:hover {
    color: ${props => props.theme.socialColor.linkedin};
  }
`;

const ConnectBlogBlock = (props) => (
    <StaticQuery
    query={graphql`
      query ConnectQuery {
        allContentfulGlobalVariables(filter: {id: {eq: "edec6887-d846-5e51-b03f-0a80904aa7d5"}}) {
          edges {
            node {
              facebookUrl
              twitterUrl
              instagramUrl
              linkedInUrl
            }
          }
        }
      }
    `}
    render={data => {
    // console.log('data:', data)

      const bg = props.bg ? props.bg : "white-bg";
      
      return (
        <ConnectBlogBlockWrap className={`${bg}`}>
          
          <Halfer className="blogSide">
            <Img className="bgImg" fluid={props.blogBg.fluid} />
            <div className="heading">What's new with us?</div>
            <Link className="theme-btn btn" to="/blog/">Check out our blog</Link>
          </Halfer>
          <Halfer className="connectSide">
            <div className="section-title pb-50">
              <h2 className="mb-15">Connect with Us:</h2>
            </div>
            <div className=" social">
              <ul>
                {data.allContentfulGlobalVariables.edges[0].node.facebookUrl &&
                  <li>
                    <a target="_blank" rel="noopener noreferrer" href={data.allContentfulGlobalVariables.edges[0].node.facebookUrl}><SocialSpan className="fab fa-facebook-f" /></a>
                  </li>
                }
                {data.allContentfulGlobalVariables.edges[0].node.twitterUrl &&
                  <li>
                    <a target="_blank" rel="noopener noreferrer" href={data.allContentfulGlobalVariables.edges[0].node.twitterUrl}><SocialSpan className="fab fa-twitter" /></a>
                  </li>
                }
                {data.allContentfulGlobalVariables.edges[0].node.instagramUrl &&
                  <li>
                    <a target="_blank" rel="noopener noreferrer" href={data.allContentfulGlobalVariables.edges[0].node.instagramUrl}><SocialSpan className="fab fa-instagram" /></a>
                  </li>
                }
                {data.allContentfulGlobalVariables.edges[0].node.linkedInUrl &&
                  <li>
                    <a target="_blank" rel="noopener noreferrer" href={data.allContentfulGlobalVariables.edges[0].node.linkedInUrl}><SocialSpan className="fab fa-linkedin-in" /></a>
                  </li>
                }
              </ul>
            </div>
          </Halfer>
          
        </ConnectBlogBlockWrap>
      )}}
    />
  )

export default ConnectBlogBlock
