import React from 'react'
import Img from 'gatsby-image'
import { StaticQuery, graphql, Link } from 'gatsby'
import styled, { keyframes } from 'styled-components'
import { rgba } from 'polished'

import theme from '../styles/theme'
import { media } from '../styles/utils'

const shuffy = keyframes`
   from {
    top: -5%;
  }

  to {
    top: -15%;
  }
`

const blink = keyframes`
   from {
    opacity: .1;
  }

  to {
    opacity: 1;
  }
`

const grow = keyframes`
   from {
    height: 100%;
  }

  to {
    height: 110%;
  }
`

const HeroWrap = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  height: calc(100vh - 110px);
  width: 100%;

  ${media.medium`
    height: auto;
  `}

  ${media.medium`
    grid-template-columns: 1fr;
  `}
`

const HeroTextWrap = styled.div`
  background-color: ${(props) => props.theme.color.lightGrey};
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 30px;
  position: relative;

  ${media.medium`
    height: 80vh;
  `}

  > h1 {
    color: ${(props) => props.theme.color.textMain};
    padding: 40px;
    padding: 0 10px;
    font-size: 60px;
    line-height: 80px;
    z-index: 2;
    text-align: center;
    width: 100%;

    ${media.large`
      font-size: 40px;
    `}
    ${media.medium`
      grid-template-columns: 1fr;
    `}
  }
`

const FadedBGImage = styled(Img)`
  width: 100%;
  height: 100%;
  /* background-image: url(${(props) => props.img}); */
  background-size: cover;
  position: absolute !important;
  top: 0;
  right: 0;
  z-index: 1;
  opacity: 0.25;
`

const BGImageWrap = styled.div`
  position: relative;

  ${media.medium`
    height: 80vh;
  `}

  .caption {
    background: ${(props) =>
      props.theme.color.black && rgba(props.theme.color.black, 0.6)};
    color: #fff;
    padding: 30px;
    position: absolute;
    right: 0;
    bottom: 0;
    width: calc(100% - 60px);
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    /* border-left: 7px solid ${(props) => props.theme.color.primary}; */

    ${media.large`
      flex-direction: column;
      align-items: flex-start;
    `}

    ${media.medium`
      width: 100%;
      justify-content: flex-end;
      align-items: center;
      text-align: center;
    `}

    :after {
      /* animation: ${shuffy} 5s ease infinite alternate; */
      animation: ${grow} 5s ease infinite alternate;
      content: '';
      position: absolute;
      left: -4px;
      bottom: 10%;
      height: 92%;
      width: 7px;
      background-color: ${(props) => props.theme.color.primary};
      /* box-shadow: 2px 2px 10px ${(props) =>
        props.theme.color.black && rgba(props.theme.color.black, 0.3)}; */
    }

    .heading {
      font-size: 18px;
      font-weight: bold;
      text-transform: uppercase;
      line-height: 1.5;
      margin-bottom: 10px;
    }

    .btn {
      margin-top: 20px;
      margin-left: 20px;

      ${media.large`
        margin-left: 0;
      `}
    }
  }
`

const BGImage = styled(Img)`
  width: 100%;
  height: 100%;
  /* background-image: url(${(props) => props.img}); */
  background-size: cover;
  position: absolute !important;
  top: 0;
  right: 0;
`

const HeroComplex = (props) => (
  <StaticQuery
    query={graphql`
      query ComplexHeroQuery {
        allContentfulHeroComplex {
          edges {
            node {
              id
              textLine1
              textLine2
              fadedImage {
                title
                fluid(maxWidth: 1800) {
                  ...GatsbyContentfulFluid_noBase64
                }
              }
              image {
                title
                fluid(maxWidth: 1800) {
                  ...GatsbyContentfulFluid_noBase64
                }
              }
              captionHeading
              captionText
              captionButtonText
              captionButtonUrl
            }
          }
        }
      }
    `}
    render={(data) => {
      return (
        <>
          {data.allContentfulHeroComplex.edges.map(({ node: hero }) => {
            if (hero.id === props.id) {
              return (
                <HeroWrap key={hero.id}>
                  <HeroTextWrap>
                    <FadedBGImage
                      fluid={hero.fadedImage.fluid}
                      backgroundColor={theme.color.lightGrey}
                    />
                    <h1>
                      {hero.textLine1}
                      <br />
                      <span className="theme-color">{hero.textLine2}</span>
                    </h1>
                  </HeroTextWrap>
                  <BGImageWrap>
                    <BGImage fluid={hero.image.fluid} />
                    <div className="caption">
                      <div>
                        <div className="heading">{hero.captionHeading}</div>
                        <span>{hero.captionText}</span>
                      </div>
                      <Link className="btn" to={hero.captionButtonUrl}>
                        {hero.captionButtonText}
                      </Link>
                    </div>
                  </BGImageWrap>
                </HeroWrap>
              )
            }
          })}
        </>
      )
    }}
  />
)
export default HeroComplex
