import React from 'react'
import { graphql } from 'gatsby'

import Layout from '../components/Layout'
import HeroComplex from '../components/HeroComplex'
import Container from '../components/Container'
import SEO from '../components/SEO'
import RecentProject from '../components/RecentProject'
import ServicesBlock from '../components/ServicesBlock'
import AboutBlock from '../components/AboutBlock'
import TopProjectsBlock from '../components/TopProjectsBlock'
import ConnectBlogBlock from '../components/ConnectBlogBlock'
import BannerSimple from '../components/BannerSimple'

const Index = ({ data }) => {
  const { hero, seo, recentProject, simpleBanner, aboutBlockContent } =
    data.contentfulHomePage

  return (
    <Layout>
      {/* {seo && <SEO pagePath="" postNode={seo} pageSEO />} */}
      {/* <SEO /> */}
      <Container>
        {hero && <HeroComplex id={hero.id} />}
        <TopProjectsBlock projectsBG={data.projectsBG} />
        <ServicesBlock bg="bg-color-2" />
        <AboutBlock bg="white-bg" id={aboutBlockContent.id} />
        {/* { recentProject &&<RecentProject data={recentProject} />} */}
        <ConnectBlogBlock bg="white-bg" blogBg={data.blogBG} />
        <BannerSimple id={simpleBanner.id} />
      </Container>
    </Layout>
  )
}

export const query = graphql`
  query {
    contentfulHomePage {
      hero {
        id
      }
      seo {
        pageTitle
        shareImage {
          ogimg: resize(width: 1800) {
            src
            width
            height
          }
        }
        metaDescription {
          metaDescription
        }
        metaKeywords {
          metaKeywords
        }
      }
      recentProject {
        name
        slug
        coverImage {
          title
          fluid(maxWidth: 570, maxHeight: 320) {
            ...GatsbyContentfulFluid_noBase64
          }
        }
      }
      simpleBanner {
        id
      }
      aboutBlockContent {
        id
      }
    }
    # file(relativePath: { eq: "abstract-bg-flipped.png" }) {
    #   childImageSharp {
    #     # Specify the image processing specifications right in the query.
    #     # Makes it trivial to update as your page's design changes.
    #     fluid(maxWidth: 570, maxHeight: 320) {
    #         ... GatsbyContentfulFluid_noBase64
    #       }
    #   }
    # }
    # HeaderImageQuery {
    #   headerImage: imageSharp(id: { regex: "/abstract-bg-flipped/" }) {
    #     fluid(maxWidth: 2200 ) {
    #       ...GatsbyImageSharpFluid
    #     }
    #   }
    # }
    projectsBG: imageSharp(
      original: { src: { regex: "/abstract-bg-flipped/" } }
    ) {
      fluid(maxWidth: 1200) {
        ...GatsbyImageSharpFluid
      }
    }
    blogBG: imageSharp(original: { src: { regex: "/blogBG/" } }) {
      fluid(maxWidth: 1200) {
        ...GatsbyImageSharpFluid
      }
    }
  }
`

export default Index
