import React from 'react'
import Img from 'gatsby-image'
import styled from 'styled-components'
import Equalizer from "react-equalizer";
import { StaticQuery, graphql, Link } from "gatsby"

import theme from '../styles/theme'
import AnchorLink from "../components/AnchorLink";

const ServicesBlock = props => (
    <StaticQuery
    query={graphql`
      query ServicesQuery {
        contentfulServicesBlock {
          headlineAccentColor
          headlineWhite
          subHeading
          buttonText
          services {
            id
            serviceName
            icon
            description {
              childMarkdownRemark {
                html
              }
            }
          }
        }
      }
    `}
    render={data => {
      let servicesInRow = data.contentfulServicesBlock.services.length
      servicesInRow =  12 / servicesInRow

      const services = []
      for(let i = 0; i < 3; i++) {
        services.push(
          <div key={data.contentfulServicesBlock.services[i].id} className={`col-xs-12 col-sm-12 col-md-4 mobi-mb-30 mb-30`}>
            <div className="service-box light-bg team-member">
              <i className={`fas fa-${data.contentfulServicesBlock.services[i].icon}`} /> 
              <h4>{data.contentfulServicesBlock.services[i].serviceName}</h4>
              <div dangerouslySetInnerHTML={{
                __html: data.contentfulServicesBlock.services[i].description.childMarkdownRemark.html,
              }}/>
            </div>
          </div>
        )
      }

      const bg = props.bg ? props.bg : "light-bg";
      
      return (
      <div className={`service-area style1 section-ptb ${bg}`}>
      <AnchorLink link={data.contentfulServicesBlock.headlineAccentColor + " " + data.contentfulServicesBlock.headlineWhite} />
      <div className="container">
        <div className="row">
          <div className="col-xs-12 text-center">
            <div className="section-title pb-80">        
              <h2 className="mb-15">
                <span className="theme-color">{data.contentfulServicesBlock.headlineAccentColor}</span> {data.contentfulServicesBlock.headlineWhite}
              </h2>
              <p>{data.contentfulServicesBlock.subHeading}</p>
            </div>
          </div>
        </div>
        <div className="row mb-30">

          <Equalizer>
            
            {services}

          </Equalizer>
          
          {/* <div className="view-all text-center">
            <Link className="btn theme-btn mt-80" to="/about#services-we-offer">{data.contentfulServicesBlock.buttonText}</Link>
          </div> */}
        </div>
      </div>
    </div>
    )}}
  />
  )

export default ServicesBlock
