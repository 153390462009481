import React from 'react'
import Img from 'gatsby-image'
import styled from 'styled-components'
import { StaticQuery, graphql, Link } from 'gatsby'

import theme from '../styles/theme'
import AnchorLink from '../components/AnchorLink'

const AboutBlock = (props) => (
  <StaticQuery
    query={graphql`
      query AboutQuery {
        allContentfulAboutBlock {
          edges {
            node {
              id
              headingNormalColor
              headingAccentColor
              content {
                childMarkdownRemark {
                  html
                }
              }
              image {
                title
                fluid(maxWidth: 1800) {
                  ...GatsbyContentfulFluid_noBase64
                }
              }
              button1Text
              button1Url
              button2Text
              button2Url
            }
          }
        }
      }
    `}
    render={(data) => {
      const bg = props.bg ? props.bg : 'white-bg'

      return (
        <>
          {data.allContentfulAboutBlock.edges.map(({ node: aboutContent }) => {
            if (aboutContent.id === props.id) {
              return (
                <div
                  key={aboutContent.id}
                  className={`hero-block style2 section-ptb ${bg}`}
                >
                  <AnchorLink
                    link={
                      aboutContent.headingNormalColor +
                      ' ' +
                      aboutContent.headingAccentColor
                    }
                  />
                  <div className="container">
                    <div className="row">
                      <div className="col-xs-12 col-sm-10 col-md-6">
                        <div className="hero-text mt-20">
                          <h2 className="mb-25">
                            {aboutContent.headingNormalColor}{' '}
                            <span className="theme-color">
                              {aboutContent.headingAccentColor}
                            </span>
                          </h2>
                          <div
                            dangerouslySetInnerHTML={{
                              __html:
                                aboutContent.content.childMarkdownRemark.html,
                            }}
                          />
                          {aboutContent.button1Text &&
                            aboutContent.button1Url && (
                              <Link
                                className="btn theme-btn mt-25"
                                to={aboutContent.button1Url}
                              >
                                {aboutContent.button1Text}
                              </Link>
                            )}
                          {aboutContent.button2Text &&
                            aboutContent.button2Url && (
                              <Link
                                className="btn theme-btn mt-25"
                                to={aboutContent.button2Url}
                              >
                                {aboutContent.button2Text}
                              </Link>
                            )}
                        </div>
                      </div>
                      <div className="col-xs-12 col-sm-10 col-md-6">
                        <div className="hero-img">
                          <Img fluid={aboutContent.image.fluid} />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )
            }
          })}
        </>
      )
    }}
  />
)

export default AboutBlock
