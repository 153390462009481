import React, { Component } from 'react'
import { slugify } from "../utils/helpers";

export default class AnchorLink extends Component {
  render() {
    const slugishLink = slugify(this.props.link)
    return (
      <div id={`${slugishLink}`} />
    )
  }
}
